import React            from 'react';
import { NavLink }      from 'react-router-dom';
import { getRoute }     from 'router/routes';
import SidebarAdmin     from 'components/Layouts/SidebarAdmin';
import SidebarInventory from 'components/Layouts/SidebarInventory';
import SidebarLibrary   from 'components/Layouts/SidebarLibrary';

export default function Sidebar(props)
{
    return (
        <aside className="layout-main-sidebar p-3 menu box">
            <p className="menu-label">General</p>
            <ul className="menu-list">
                <li><NavLink to={getRoute('dashboard')}>Dashboard</NavLink></li>
            </ul>

            <SidebarInventory />
            <SidebarLibrary />
            <SidebarAdmin />
        </aside>
    )
}

import React                   from 'react';
import { NavLink }             from 'react-router-dom';
import * as API                from 'api';
import SidebarInventoryIsotope from 'components/Layouts/SidebarInventoryIsotope';
import { getRoute }            from 'router/routes';

export default function SidebarInventory(props)
{
  if (API.user.doesNotHaveAccess(['inventory_collections']) === true) {
    return null;
  }

  return (
    <>
      <p className="menu-label">Inventory</p>
      <ul className="menu-list">
        <SidebarInventoryIsotope />
        <li>
          <NavLink to={getRoute('inventory', { channel: 'isotope' })}>
            Inventory
          </NavLink>
        </li>
        <li>
          <NavLink to={getRoute('categories')}>
            Categories
          </NavLink>
        </li>
      </ul>
    </>
  )
}

import React             from 'react';
import { Route, Switch } from 'react-router-dom';
import Header            from 'components/Layouts/Header';
import Footer            from 'components/Layouts/Footer';
import NotFound          from 'components/Layouts/NotFound';
import Sidebar           from 'components/Layouts/Sidebar';
import * as Pages        from 'components/Pages';
import Loading           from 'components/Pages/Loading';
import { getRoute }      from 'router/routes';

export default function Main(props)
{
  return (
    <React.Fragment>
      <Header />

      <div className="layout-main-container container">
        <div className="columns">
          <div className="column is-2 pt-4">
            <Sidebar />
          </div>
          <div className="column">
            <React.Suspense fallback={<Loading />}>
              <Switch>
                <Route
                  component={Pages.Categories}
                  path={getRoute('categories')}
                />
                <Route
                  component={Pages.Collections}
                  path={getRoute('collections')}
                />
                <Route
                  component={Pages.Dashboard}
                  exact
                  path={getRoute('dashboard')}
                />
                <Route
                  component={Pages.Inventory}
                  exact
                  path={getRoute('inventory')}
                />
                <Route
                  component={Pages.Library}
                  path={getRoute('library')}
                />
                <Route
                  component={Pages.Users}
                  path={getRoute('adminUsers')}
                  exact
                />
                <Route
                  component={Pages.Channels}
                  path={getRoute('adminChannels')}
                  exact
                />
                <Route component={NotFound} />
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  )
}

export const routes = {
    'dashboard':                    '/admin/dashboard',
    'categories':                   '/admin/categories',
    'collections':                  '/admin/collections',
    'collection':                   '/admin/collections/:collectionId',
    'inventory':                    '/admin/inventory/:channel',
    'library':                      '/admin/library',
    'libraryAlbums':                '/admin/library/albums',
    'libraryAlbum':                 '/admin/library/albums/:albumSlug',
    'libraryAlbum.edit':            '/admin/library/albums/:albumSlug/edit',
    'libraryAlbum.new':             '/admin/library/albums/new',
    'libraryArtists':               '/admin/library/artists',
    'libraryArtist':                '/admin/library/artists/:artistSlug',
    'libraryArtist.edit':           '/admin/library/artists/:artistSlug/edit',
    'libraryArtist.new':            '/admin/library/artists/new',
    'libraryFormats':               '/admin/library/formats',
    'libraryGenres':                '/admin/library/genres',
    'libraryImport':                '/admin/library/import',
    'adminUsers':                   '/admin/users',
    'adminChannels':                '/admin/channels',
    'login':                        '/admin/login',
    'logout':                       '/admin/logout',
    'unauthorized':                 '/admin/unauthorized',
};

export default routes;

export function getRoute(routeKey, params = {}, queryString = '')
{
    let routePath = routes[routeKey];

    if (queryString !== '') {
        routePath = `${routePath}?${queryString}`;
    }

    Object.keys(params).map((item, key) => {
        routePath = routePath.replace(`:${item}`, params[item]);
    });

    return routePath;
}

import * as collections         from 'api/collections';
import * as requests            from 'api/requests';
import * as user                from 'api/user';
import * as libraryAlbums       from 'api/libraryAlbums';
import * as libraryArtists      from 'api/libraryArtists';
import * as libraryContributors from 'api/libraryContributors';
import * as libraryFormats      from 'api/libraryFormats';
import * as libraryGenres       from 'api/libraryGenres';
import * as libraryImport       from 'api/libraryImport';
import * as errors              from 'api/errors';

const library = {
    albums:       libraryAlbums,
    artists:      libraryArtists,
    contributors: libraryContributors,
    formats:      libraryFormats,
    genres:       libraryGenres,
    import:       libraryImport,
};

export {
    collections,
    errors,
    library,
    requests,
    user,
};

export function init()
{
    user.sessionInit();
};

export function progress(on = true)
{
    // storeDispatch(
    //     storeTypes.QBERT.PROGRESS_UPDATE,
    //     on
    // );
}

export function clipboard(value, message = '')
{
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    if (message) {
        success(message);
    }

}

/**
 * When called with a true argument, the class "is-clipped" is added to the <html>
 * tag, which the Bulma CSS library being used will add an overflow: hidden to
 * the element. This will prevent the body from scrolling, when the modal is open,
 * which will allow "long" content in the modal to scroll without janky behaviour
 * from the body document of the page.
 *
 * @param {boolean} open
 * @returns void
 */
export function modalOpen(open = true)
{
    return open === true ?
        document.getElementsByTagName('html')[0].setAttribute('class', 'is-clipped') :
        document.getElementsByTagName('html')[0].removeAttribute('class');
}

/**
 * Eloquent way to call the modalOpen method to unset the is-clipped class name
 * on the <html> tag to avoid scrolling the background while a modal is open.
 *
 * @returns void
 */
export function modalClose()
{
    return modalOpen(false);
}

export function success(message)
{
    console.log('SUCCESS', message);
}

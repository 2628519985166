import React from 'react';

export default function DinAdmin(props)
{
    let classNames = [
        'display-dilatednetwork-administration',
    ];

    if (typeof props.classNames !== 'undefined') {
        classNames.push(props.classNames);
    }

    return (
        <div className={classNames.join(' ')}>
            <span className="bold">Dilated Network</span> | <span className="highlight">Administration</span>
        </div>
    )
}

import React                      from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { getRoute }               from 'router/routes';
import * as API                   from 'api';

export default function SidebarLibrary(props)
{
    if (API.user.doesNotHaveAccess([
        'library_import',
        'library_albums',
        'library_artists',
        'library_formats'
    ]) === true) {
        return null;
    }

    return (
        <React.Fragment>
            <p className="menu-label">Library</p>
            <ul className="menu-list">
                <LibraryImport />
                <LibraryAlbums />
                <LibraryArtists />
                <LibraryFormats />
                <LibraryGenres />
            </ul>
        </React.Fragment>
    )
}

function LibraryImport() {
    if (API.user.doesNotHaveAccess('library_import') === true) {
        return null
    }

    return <li><NavLink to={getRoute('libraryImport')}>Import</NavLink></li>
}

function LibraryFormats() {
    if (API.user.doesNotHaveAccess('library_formats') === true) {
        return null
    }

    return <li><NavLink to={getRoute('libraryFormats')}>Formats</NavLink></li>
}

function LibraryGenres() {
    if (API.user.doesNotHaveAccess('library_genres') === true) {
        return null
    }

    return <li><NavLink to={getRoute('libraryGenres')}>Genres</NavLink></li>
}

function LibraryAlbums() {
    if (API.user.doesNotHaveAccess('library_albums') === true) {
        return null
    }

    const MainLink = () => {
        if (API.user.hasAccess('library_albums.read') === false) {
            return null
        }

        return <NavLink to={getRoute('libraryAlbums')}>Albums</NavLink>
    }

    const NewLink = () => {
        if (API.user.hasAccess('library_albums.write') === false) {
            return null
        }

        // For both the Albums "new" link, we need to check if we're ON the "new"
        // route already and if so, we have to make an <a> tag, opposed to changing
        // the router. The page is already loaded and the component is not
        // refreshed to open up the "new" form.
        return useRouteMatch({ path: getRoute('libraryAlbum.new')}) !== null ?
            <a
                className="button is-dark is-small mr-2"
                href={getRoute('libraryAlbum.new')}
            >
                New
            </a> :
            <NavLink
                className="button is-dark is-small mr-2"
                to={getRoute('libraryAlbum.new')}
            >
                New
            </NavLink>
    }

    return (
        <li className="has-button">
            <MainLink />
            <NewLink />
        </li>
    )
}

function LibraryArtists() {
    if (API.user.doesNotHaveAccess('library_artists') === true) {
        return null
    }

    const MainLink = () => {
        if (API.user.hasAccess('library_artists.read') === false) {
            return null
        }

        return <NavLink to={getRoute('libraryArtists')}>Artists</NavLink>
    }

    const NewLink = () => {
        if (API.user.hasAccess('library_artists.write') === false) {
            return null
        }

        // For both the Artists "new" link, we need to check if we're ON the "new"
        // route already and if so, we have to make an <a> tag, opposed to changing
        // the router. The page is already loaded and the component is not
        // refreshed to open up the "new" form.
        return useRouteMatch({ path: getRoute('libraryArtist.new')}) !== null ?
            <a
                className="button is-dark is-small mr-2"
                href={getRoute('libraryArtist.new')}
            >
                New
            </a> :
            <NavLink
                className="button is-dark is-small mr-2"
                to={getRoute('libraryArtist.new')}
            >
                New
            </NavLink>
    }

    return (
        <li className="has-button">
            <MainLink />
            <NewLink />
        </li>
    )
}

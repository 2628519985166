import { merge as _merge } from 'lodash';
import { sessionGet }      from 'api/user';
import deepmerge from 'deepmerge';

const axios = require('axios');

// Standard headers that should go along with every request to the backend.
let axiosHeaders = {
  'Content-Type':  'application/json',
  'Accept':        'application/json',
  'Authorization': `Bearer ${process.env.DILATED_TOKEN}`,
};

// Try to get the session token out of localStorage. If it's there, we need to
// include it in requests to the backend to enforce ACLs, so we're putting into
// the headers included with the Axios requests.
const sessionToken = sessionGet('token');
if (sessionToken !== false) {
  axiosHeaders = deepmerge(
      axiosHeaders,
      { 'X-Authorization': `Bearer ${sessionToken}` }
  );
}

axios.defaults.headers.common = axiosHeaders;
axios.interceptors.response.use(function (response) {

    // // Since 202 was being used successful queued responses,
    // // the interceptor’s “redirect” status will be updated to 317 since
    // // it's unused by RFC and data can be passed back with it.
    // if (response.status === 317) {
    //     document.location = response.data.redirect;
    // }

    return response;
}, function (error) {
    // processError(error);

    // Do something with response error
    return Promise.reject(error.response.data);
});

const defaultOptions = {
    api:    process.env.DILATED_API,
    params: {},
};

export default function api(requestURL, options = {})
{
  // const mergedOptions = mergeRequestOptions(options);
  const axiosInstance = axios.create({
    baseURL: process.env.DILATED_API,
  });

  return axiosInstance;
}

export function mergeRequestOptions(options)
{
    // Defaulted request header to application/json
    let requestHeader = {
        'Content-Type':  'application/json',
    };

    // If content-type is passed in for the request header
    // remove the default. (Most cases default will be used.)
    if (options.headers && options.headers['Content-Type']) {
        requestHeader = {}
    }

    return _merge(
            {},
            defaultOptions,
            requestHeader,
            options
        );
}

export function processError(error)
{
  console.log('processing error', error)
    API.progress(false);

    // default generate message
    let message = 'Request Error';
    let data    = {
        title: `${error.response.status} ${message}`,
        message: message
    };

    // The request was made and the server responded with a status code
    if (error.response) {
        data.message = `${error.response.status < 500 ? 'Data' : 'Service'} Unavailable`;

        // If we have specific code/message from the caller, we'll use those for
        // the content of the error toast.
        if (typeof error.response.data.code !== 'undefined' &&
            typeof error.response.data.message !== 'undefined'
        ) {
            data.message = `${error.response.data.code}: ${error.response.data.message}`;
        }
    }

    console.error(data);
}

import * as API from 'api';

export function getContributors(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s:    searchValue,
                size: 1000
            },
        };
    }

    return API.requests.GET('admin/library/contributors', options)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function getRoles(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s:    searchValue,
                size: 1000
            },
        };
    }

    return API.requests.GET('admin/library/contributors/roles', options)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

import React from 'react';

export function error(errorFields, field, message)
{
    return Object.assign({}, errorFields, {
        [field]: message
    });
}

export function className(errorFields, field)
{
    return typeof errorFields[field] !== 'undefined' ?
        ' is-danger' :
        '';
}

export function errorMessage(errors, field, className = '')
{
  return (
    <p className={`help is-danger${className !== '' ? ` ${className}` : ''}`}>
      {errors[field]?.message}
    </p>
  )
}


export function message(errorFields, field, className = '')
{
    return typeof errorFields[field] !== 'undefined' ?
        <p className={`help is-danger${className !== '' ? ` ${className}` : ''}`}>
            {errorFields[field]}
        </p> :
        null;
}

export function isClean(errorFields)
{
    return Object.keys(errorFields).length === 0;
}

export function addError(errorFields, field, message) {
    return Object.assign({}, errorFields, {
        [field]: message,
    });
}

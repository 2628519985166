import { app as types } from 'store/reducers/storeTypes';

const initialState = {
    hasModal: false,
}

const appReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.HAS_MODAL:
            return {
                ...state,
                hasModal: typeof action.data !== 'undefined' ?
                    action.data :
                    true
            };
    }

    return state;
}

export default appReducer;

import React, { useEffect } from 'react';
import { Route, Switch }    from 'react-router-dom';
import * as API             from 'api';
import Login                from 'components/Layouts/Login';
import Logout               from 'components/Layouts/Logout';
import Main                 from 'components/Layouts/Main';
import ChannelProvider      from 'providers/ChannelProvider';
import routes               from 'router/routes';
import SessionTopUp         from 'router/SessionTopUp';

export default function App(props)
{
  useEffect(() => {
    API.init();
  });

  return (
    <Switch>
      <Route exact path={routes['logout']} component={Logout} />
      <Route exact path={routes['login']} component={Login} />
      <SessionTopUp>
        <ChannelProvider>
          <Route path="/admin" component={Main} />
        </ChannelProvider>
      </SessionTopUp>
    </Switch>
  )
}

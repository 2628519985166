import * as API from 'api';

export function get(slug = '')
{
    slug = slug !== '' ?
        `/${slug}` :
        '';

    return API.requests.GET(`admin/library/artists${slug}`)
        .then(response => {
            return new Promise(resolve => {
                resolve(response.data);
            });
        });
}

export function getArtists(searchValue = '')
{
    let options = {};

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    return API.requests.GET('admin/library/artists', options)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function saveArtist(id, payload, editing = false)
{
    let data = {
        data: payload,
    };

    return (editing === false ?
        API.requests.POST('admin/library/artists', data) :
        API.requests.PUT(`admin/library/artists/${id}`, data))
            .then(response => {

            })
            .catch(error => {
                console.error('Oops', error.response.data);
                throw error.response.data;
            });
}

export function toggleArtistLock(id, isLocked)
{
    const method = isLocked === true ?
        'lock' :
        'unlock';

    return API.requests.GET(`admin/library/artists/${id}/${method}`)
        .then(response => {

        })
        .catch(error => {
            console.error('Oops', error.response.data);
            throw error.response.data;
        });
}

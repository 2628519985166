import * as API from 'api';

export function get(slug = '')
{
    slug = slug !== '' ?
        `/${slug}` :
        '';

    return API.requests.GET(`admin/library/albums${slug}`)
        .then(response => {
            return new Promise(resolve => {
                resolve(response.data);
            });
        });
}

export function getAlbums(searchValue = '')
{
    let options = {};

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    return API.requests.GET('admin/library/albums', options)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function getFormats(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
                size: 1000,
            },
        };
    }

    return API.requests.GET('admin/library/formats', options)
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function getFormatCodes(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
                size: 1000,
            },
        };
    }

    return API.requests.GET('admin/library/format-codes', options)
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function getGenreCodes(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
                size: 1000,
            },
        };
    }

    return API.requests.GET('admin/library/genre-codes', options)
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function getRecordingTypes(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
                size: 1000,
            },
        };
    }

    return API.requests.GET('admin/library/recording-types', options)
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function getPublishers(searchValue = '')
{
    let options = { params: { size: 1000 } };

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
                size: 1000,
            },
        };
    }

    return API.requests.GET('admin/library/albums/publishers', options)
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function changeContributorsPosition(albumId, contributorId, position)
{
    return API.requests.POST(
        `admin/library/albums/${albumId}/contributors/${contributorId}/position`,
        {
            data: {
                position: position
            }
        }
    );
}

export function saveAlbum(id, payload, editing = false)
{
    let data = {
        data: payload,
    };

    return (editing === false ?
        API.requests.POST('admin/library/albums', data) :
        API.requests.PUT(`admin/library/albums/${id}`, data))
            .then(response => {

            })
            .catch(error => {
                console.error('Oops', error.response.data);
                throw error.response.data;
            });
}

export function toggleAlbumLock(id, isLocked)
{
    const method = isLocked === true ?
        'lock' :
        'unlock';

    return API.requests.GET(`admin/library/albums/${id}/${method}`)
        .then(response => {

        })
        .catch(error => {
            console.error('Oops', error.response.data);
            throw error.response.data;
        });
}

/**
 * Translates a track/album duration from a float value to a time value. The
 * durations in the library are stored as 4.28 which means 4:28.
 *
 * @param {float} time
 */
export function floatToTime(time)
{
    if (time === '') {
        return '00:00';
    }

    const parts = String(time).indexOf('.') === -1 ?
        [time, '00'] :
        String(time).split('.');

    if (parts[1].length < 2) {
        parts[1] += '0';
    }

    return parts.join(':');
}

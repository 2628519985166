import React                       from 'react';
import ReactDOM                    from 'react-dom';
import { Provider }                from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as API                    from 'api';
import App                         from 'components/App';
import ScrollToTop                 from 'router/ScrollToTop';
import createStore                 from 'store/createStore';

import './scss/main.scss';

const store = createStore();

if (module.hot) {
  module.hot.accept();
}

const doRender = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </Provider>,
    document.getElementById('root'),
  )
}

API.user.reloadUserThen(doRender)
  .catch(error => {
    console.error(error);
    doRender();
  });

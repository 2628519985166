const app = {
    APP_HAS_MODAL: 'app.has_modal',
};

const user = {
    USER_REMOVE: 'user.remove',
    USER_UPDATE: 'user.update',
    USER_GET:    'user.get',
};

const storeTypes = {
    APP : app,
    USER: user,
};

export default storeTypes;

export {
    app,
    user,
};

import * as API from 'api';

export function getGenres(searchValue = '', count = null)
{
    let options = {};

    if (typeof searchValue === 'string' && searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    if (typeof searchValue === 'object') {
        options = {
            params: searchValue
        };
    }

    if (count !== null) {
        options.params['size'] = count;
    }

    return API.requests.GET('admin/library/genres', options)
        .then(response => {
            return response.data;
        });
}

export function getGenreCodes(searchValue = '')
{
    let options = {};

    if (typeof searchValue === 'string' && searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    if (typeof searchValue === 'object') {
        options = {
            params: searchValue
        };
    }

    return API.requests.GET('admin/library/genre-codes', options)
        .then(response => {
            return response.data;
        });
}

export function saveGenre(id, type, genre, editing = false)
{
    let data = {
        data: {
            type:  type,
            genre: genre,
        },
    };

    return editing === false ?
        API.requests.POST('admin/library/genres', data) :
        API.requests.PUT(`admin/library/genres/${id}`, data);
}

export function saveGenreCode(id, code, mainId, subId, editing = false)
{
    let data = {
        data: {
            code:   code,
            mainId: mainId,
            subId:  subId,
        },
    };

    return editing === false ?
        API.requests.POST('admin/library/genre-codes', data) :
        API.requests.PUT(`admin/library/genre-codes/${id}`, data);
}

export function saveGenreAndCode(mainId, code, subGenre)
{
    return API.requests.POST('admin/library/genre-and-codes', {
        data: {
            mainId:    mainId,
            code:      code,
            subGenre: subGenre,
        },
    });
}

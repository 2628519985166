import React from 'react';

export default function Footer(props)
{
    return (
        <footer className="layout-main-footer">
            Ver: {process.env.APP_VERSION}
        </footer>
    )
}

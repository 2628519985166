import { user as types } from 'store/reducers/storeTypes';
import * as API           from 'api';

const initialState = {
    user: false,
}

const userReducer = function(state = initialState, action) {
    switch(action.type) {
        case types.USER_REMOVE:
            return {
                ...state,
                user: false
            };
        case types.USER_UPDATE:
            return {
                ...state,
                user: action.data
            };
        case types.USER_GET:
            return {
                ...state,
                user: API.user.sessionGet() || false
            };
    }

    return state;
}

export default userReducer;

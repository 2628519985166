import React, { useEffect, useState, } from 'react';
import api                             from 'lib/api';

const ChannelContext = React.createContext();

export default function ChannelProvider(props)
{
  const [processing, setProcessing] = useState(false);
  const [channels, setChannels] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [channelFormOpenState, setChannelFormOpenState] = useState(false);
  const [editChannel, setEditChannel] = useState(null);
  const [filterChannel, setFilterChannel] = useState('')

  useEffect(() => {
    doGetChannels();
  }, []);

  useEffect(() => {
    const options = [];

    channels.map((channel, idx) => {
      options.push({
        value: channel.id,
        label: channel.name
      });
    });

    setChannelOptions(options);
  }, [channels]);

  const doGetChannels = (channel) => {
    setProcessing(true);
    let params = {};

    // If we have an incoming channel value, need to fiter for it.
    if (channel) {
      params = Object.assign({params: {s: channel}});
    }

    api().get(`/admin/channels`, params)
      .then(response => {
        setChannels(response.data.data);
      })
      .finally(() => {
        setProcessing(false);
      })
  }

  const channelFormOpen = (state = null) => {
    if (state !== null) {
      setChannelFormOpenState(state);
      clearEditChannel();

      return;
    }

    setChannelFormOpenState(current => !current);
  }

  const doEditChannel = (channel) => {
    setEditChannel(channel);
    setChannelFormOpenState(true);
  }

  const doCloseEditChannel = () => {
    clearEditChannel();
    setChannelFormOpenState(false);
  }

  const clearEditChannel = () => {
    setEditChannel(null);
  }

  const doSaveChannel = (formData) => {
    setProcessing(true);

    const isAdding = editChannel === null;
    const url = isAdding ?
      '/admin/channels' :
      `/admin/channels/${editChannel.id}`;

    if (formData.invoicePurchases === undefined) {
      formData.invoicePurchases = false;
    }

    if (formData.displayShipping === undefined) {
      formData.displayShipping = false;
    }

    if (formData.displayTax === undefined) {
      formData.displayTax = false;
    }

    return (isAdding ? api().post(url, formData) : api().put(url, formData))
      .then(response => {
        setProcessing(false);

        return new Promise(resolve => resolve(response));
      })
  };

  return (
    <ChannelContext.Provider
      value={{
        channelFormOpen,
        channelFormOpenState,
        channelOptions,
        channels,
        clearEditChannel,
        doCloseEditChannel,
        doGetChannels,
        doEditChannel,
        doSaveChannel,
        editChannel,
        filterChannel,
        processing,
        setFilterChannel,
      }}
    >
      {props.children}
    </ChannelContext.Provider>
  )
}

export function useChannel() {
  const context = React.useContext(ChannelContext)

  if (context === undefined) {
    throw new Error('useChannel must be used within a ChannelProvider')
  }

  return context
}

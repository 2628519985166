import React                         from 'react';
import Encrypter                     from 'object-encrypter';
import * as API                      from 'api';
import { getRoute }                  from 'router/routes';
import { storeDispatch, storeTypes } from 'store/createStore';
import Unauthorized                  from 'components/Layouts/Unauthorized';

const SESSION_KEY    = 'user'
const SESSION_LENGTH = 180;         // Expressed in Mins
const TTL_UNITS      = (60 * 1000); // Expressed in Milliseconds (Set to: 1-min in ms).
const KEY            = 'ewfWE@#%$rfdsefgdsf';
const ADMIN_ACCESS   = 'admin';
const sessionEncrypt = Encrypter( KEY, { ttl: true } );

export const POST_LOGIN_ROUTE = 'dashboard';

export function login(username, password)
{
  let data = {
    data: {
      email:    username,
      password: password
    }
  };

  return API.requests.POST('admin/login', data)
    .then(response => {
      if (response.data.data === false) {
        console.error(response.data.message || defaultMessage);
        return false;
      }

      return sessionStore(response.data.data);
    })
    .then(response => {
      // Successful Login, initialize calls for store data.
      API.init();

      return new Promise(resolve => {
        resolve(response);
      });
    })
}

export function logout()
{
    return sessionRemove();
}

export function reloadUserThen(callback)
{
    const  id = API.user.sessionGet('id'),
        email = API.user.sessionGet('email'),
        token = API.user.sessionGet('token');

    if (id === false || email === false || token === false) {
        return new Promise((resolve, reject) => {
            reject('No session to reload');
        })
    }

    let data = {
        data: {
            id:    API.user.sessionGet('id'),
            email: API.user.sessionGet('email'),
            token: API.user.sessionGet('token'),
        },
    };

    return API.requests.POST('admin/reload-session', data)
        .then(response => {
            return sessionStore(response.data.data);
        })
        .catch(error => {
            throw `${error.response.data.data[0]} [${error.response.data.code}]`;
        })
        .finally(response => callback());
    callback();
    // return QBERT.requests.GET('user')
    //     .then(response => {
    //         console.log('User logged in:');
    //         console.log(response);
    //         if(response.data !== false){
    //             sessionStore(response.data.data);
    //         }
    //     })
    //     .catch( (error) => {
    //         console.log('No user logged in.');
    //         return false;
    //     })
    //     .finally(
    //         response => callback()
    //     )
    //     ;
}

export function sessionInit()
{
    let user = this.sessionGet();

    if (user === false) {
        sessionRemove();
    }

    // If there IS a user, start the sessionChecker.
    if (user !== false) {
        sessionCheck();
    }

    return user;
}

export function sessionGet(item = null, key = SESSION_KEY)
{
    // Try to get the key asked for out of localStorage.
    const data = sessionEncrypt.decrypt(localStorage.getItem(key));

    // If the key supplied doesn't exist in localStorage, there isn't anything
    // more we can do here.
    if (data === null) {
        return false;
    }

    // If we've been asked for a particular item out of the data we got from
    // localStorage, try to send that back, false otherwise.
    if (item !== null) {
        return data[item] || false;
    }

    // Down here means we've been asked for the whole key, easy.
    return data;
}

export function sessionRemove(key = SESSION_KEY)
{
    localStorage.removeItem(key);

    storeDispatch(
        storeTypes.USER.USER_REMOVE,
        false
    );

    clearInterval(window.sessionTimer);
    window.sessionTimer = null;

    return new Promise(resolve => resolve(true));
}

/**
 * Re-stores the current session to extend the TTL of the encryption. This should
 * be called whenever there's a "browsing" action by the user.
 */
export function sessionTouch(key = SESSION_KEY)
{
    return sessionStore(sessionGet(key));
}

export function sessionStore(data, key = SESSION_KEY, ttl = SESSION_LENGTH)
{
    // If no user data provided, DONT encrypt and/or store.
    if (data === false) {
        return false;
    }

    // Defaulting the timezone value to 'America/Toronto' for now. Ultimately,
    // this should come from the user record in the backend, but that's for
    // later.
    data = Object.assign(
        {},
        data,
        { timezone: 'America/Toronto' }
    );

    // Encrypt data
    let item = sessionEncrypt.encrypt(data, (ttl * TTL_UNITS));

    // Store User in Session.
    localStorage.setItem(key, item);

    storeDispatch(
        storeTypes.USER.USER_UPDATE,
        data
    );

    return new Promise(resolve => {
        resolve(data);
    });
}

export function sessionCheck(set = true)
{
    window.sessionTimer = setInterval(() => {
        if (sessionGet() === false) {
            sessionRemove()
                .then(response => {
                    document.location = getRoute('login', {}, 'timedout=1');
                });
        }
    }, 3000);
}

export function hasAccess(requiredLevels)
{
    const isAdmin = sessionGet('isAdmin');

    if (isAdmin === true) {
        return true;
    }

    let hasAccess  = false;
    const accesses = sessionGet('access');

    if (accesses === false) {
        return false;
    }

    requiredLevels = typeof requiredLevels === 'string' ?
        requiredLevels.split(',') :
        requiredLevels;

    requiredLevels.map(requiredLevel => {
        // If we've already determined that user has access, we'll short-circuit
        // here, things are good.
        if (hasAccess === true) {
            return;
        }

        const level = requiredLevel.split('.');

        accesses.map(access => {
            // If we've already determined that user has access, we'll short-circuit
            // here, things are good.
            if (hasAccess === true) {
                return;
            }

            // // If the group of user's access happens to be the ADMIN_ACCESS group,
            // // user is an admin, so we don't need to compare anymore.
            // if (access.group === ADMIN_ACCESS) {
            //     hasAccess = true;
            //     return;
            // }

            // If the required level parts only have one element, this means the
            // required level is only caring about the group portion, so we only
            // to determine the access based on having the group in the ACLs. If
            // the length is more than 1, we'll have to compare the label as well.
            //
            // If user has for example "import.read", and the required level is
            // only "import", by virtue of being able to "import.read", the user
            // should be allowed to see the thing only requring "import", because
            // the access needed is more relaxed.
            hasAccess = level.length === 1 ?
                access.group === level[0] :
                access.group === level[0] && access.label === level[1];
        });
    });

    return hasAccess;
}

/**
 * The negated response to hasAccess(), for more fluent comparisons in the application.
 * @param {string} requiredLevels
 * @returns boolean
 */
export function doesNotHaveAccess(requiredLevels)
{
    return ! hasAccess(requiredLevels);
}

export function isUnauthorized(renderOutput = true)
{
    return renderOutput === true ?
        <Unauthorized /> :
        null;
}

export function generatePassword()
{
    return API.requests.GET('admin/users/generate-password')
        .then(response => {
            return response.data.data[0];
        })
        .catch(error => {
            console.error('oops', error.response.data);
        });
}

export function getUsers(searchValue = '')
{
    let options = {};

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    return API.requests.GET('admin/users', options)
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

export function saveUser(
    id,
    name,
    email,
    password,
    isAdmin,
    selectedAccess,
    editing = false
) {
    let data = {
        data: {
            name:    name,
            email:   email,
            isAdmin: isAdmin,
            access:  selectedAccess,
        },
    };

    if (password !== '') {
        data.data['password'] = password;
    }

    return (editing === false ?
        API.requests.POST('admin/users', data) :
        API.requests.PUT(`admin/users/${id}`, data))
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                console.error('Oops', error.response.data);
            });
}

export function enableUser(id, callback = null)
{
    return API.requests.PATCH(`admin/users/${id}`, {
        data: {
            enabled: true
        }
    })
        .then(response => {
            return callback === null ?
                new Promise(resolve => resolve(response)) :
                callback();
        });
}

export function disableUser(id, callback = null)
{
    return API.requests.PATCH(`admin/users/${id}`, {
        data: {
            enabled: false
        }
    })
        .then(response => {
            return callback === null ?
                new Promise(resolve => resolve(response)) :
                callback();
        });
}

export function removeUser(id, callback = null)
{
    return API.requests.DELETE(`admin/users/${id}`)
        .then(response => {
            return callback === null ?
                new Promise(resolve => resolve(response)) :
                callback();
        });
}

export function getAccess()
{
    return API.requests.GET('admin/users/access')
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error('Oops', error.response.data);
        });
}

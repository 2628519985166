import React                  from 'react';
import { NavLink }            from 'react-router-dom';
import * as API               from 'api';
import CollapsibleSidebarMenu from 'components/Utilities/CollapsibleSidebarMenu';
import { getRoute }           from 'router/routes';

export default function SidebarInventoryIsotope(props)
{
  if (API.user.doesNotHaveAccess(['inventory_collections']) === true) {
    return null;
  }

  return (
    <CollapsibleSidebarMenu
      label="Isotope"
    >
      <ul className="menu-list">
        <li><NavLink to={getRoute('collections', { channel: 'isotope' })}>Collections</NavLink></li>
      </ul>
    </CollapsibleSidebarMenu>
  )
}

import React, { useState } from 'react';

export default function CollapsibleSidebarMenu(props)
{
  const { children, label, open } = props;
  const [isOpen, setIsOpen] = useState(open)

  return (
    <li className="no-hover">
      <p
        className="menu-label is-sublabel is-flex is-justify-content-space-between is-clickable"
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
        <i className={`kikol kiko-triangular-arrow-${isOpen ? 'up' : 'down'}`} />
      </p>
      {isOpen ? children : null}
    </li>
  )
}

import React        from 'react';
import { NavLink }  from 'react-router-dom';
import DinAdmin     from 'components/Displays/DinAdmin';
import { getRoute } from 'router/routes';

export default function Header(props)
{
    return (
        <header className="layout-main-header">
            <div className="columns is-vcentered">
                <div className="column is-one-quarter-tablet pl-4">
                    <DinAdmin classNames="white has-text-left ml-4" />
                </div>
                <div className="column is-half-tablet">
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input className="input" type="text" placeholder="Super Search" />
                        </div>
                        <div className="control">
                            <a className="button is-highlighted">
                            Search
                            </a>
                        </div>
                    </div>
                </div>
                <div className="column has-text-right pr-4">
                    <span className="icon mr-4">
                        <NavLink to={getRoute('logout')}>
                            <i className="kikol kiko-off"></i>
                        </NavLink>
                    </span>
                </div>
            </div>
        </header>
    )
}

import { useEffect }                from 'react';
import { sessionGet, sessionTouch } from 'api/user';
import { getRoute }                 from 'router/routes';

const SessionTopUp = ({ children }) => {
    useEffect(() => {
        sessionTouch();
    });

    // If there is no session, we'll change the document.location to the login
    // route.
    if ((sessionGet() || false) === false) {
        document.location = getRoute('login');
    }

    return children;
};

export default SessionTopUp;

import * as API from 'api';

export function get(collectionId = '')
{
    collectionId = collectionId !== '' ?
        `/${collectionId}` :
        '';

    return API.requests.GET(`admin/collections${collectionId}`)
        .then(response => {
            return new Promise(resolve => {
                resolve(response.data);
            });
        });
}

export function search(searchValue)
{
    return API.requests.POST(`admin/search`, {
        data: {
            s: searchValue,
            f: 'barcode,title,artist'
        }
    });
}

export function exportCollection(collectionId, exportFormat)
{
    return API.requests.POST(`admin/collections/${collectionId}/export`, {
        responseType: 'blob',
        data: {
            exportFormat: exportFormat
        }
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: response.headers['content-type']
            }));

            const filename = response.headers["content-disposition"]
                .split("filename=")[1]
                .replace('"', '')
                .replace('"', '');

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);

            link.click();
        });
}

export function saveCollection(collectionId, data)
{
    return API.requests.PUT(`admin/collections/${collectionId}`, {
        data: data
    });
}

export function addItem(collectionId, barcode)
{
    return API.requests.POST(`admin/collections/${collectionId}/item`, {
        data: {
            barcode: barcode,
        }
    });
}

export function removeItem(collectionId, itemId)
{
    return API.requests.DELETE(`admin/collections/${collectionId}/item/${itemId}`);
}

import React          from 'react';
import { useHistory } from 'react-router-dom';
import * as API       from 'api';
import { getRoute }   from 'router/routes';

export default function Logout(props)
{
    const history = useHistory();

    API.user.logout()
        .then(response => {
            history.push(getRoute('login'));
        })

    return null;
}

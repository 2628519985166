import React        from 'react';
import { NavLink }  from 'react-router-dom';
import * as API     from 'api';
import { getRoute } from 'router/routes';

export default function SidebarAdmin(props)
{
  if (API.user.doesNotHaveAccess('admin_users') === true) {
    // return null;
  }

  return (
    <React.Fragment>
      <p className="menu-label">Administration</p>
      <ul className="menu-list">
        <li><NavLink to={getRoute('adminUsers')}>Users</NavLink></li>
      </ul>
      <ul className="menu-list">
        <li><NavLink to={getRoute('adminChannels')}>Channels</NavLink></li>
      </ul>
    </React.Fragment>
  )
}

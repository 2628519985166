import * as API from 'api';

export function getImports(searchValue = '')
{
    let options = {};

    if (searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    return API.requests.GET('admin/library/imports', options)
        .then(response => {
            return response.data.data;
        });
}

export function getImportAdapters()
{
    return API.requests.GET('admin/library/imports/adapters')
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            throw `${error.response.data.data[0]} [${error.response.data.code}]`;
        });
}

export function uploadImport(importFile, importAdapter)
{
    let formData = new FormData();
    formData.append('file', importFile);
    formData.append('adapter', importAdapter);

    return API.requests.POST('admin/library/imports', {
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

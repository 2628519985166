import React from 'react';

const Categories = React.lazy(() => import(
  /* webpackChunkName: "categories" */ "components/Pages/Categories"
));
const Channels = React.lazy(() => import(
  /* webpackChunkName: "channels" */ "components/Pages/Channels"
));
const Collections = React.lazy(() => import(
  /* webpackChunkName: "collections" */ "components/Pages/Collections"
));
const Dashboard = React.lazy(() => import(
  /* webpackChunkName: "dashboard" */ "components/Pages/Dashboard"
));
const Inventory = React.lazy(() => import(
  /* webpackChunkName: "inventory" */ "components/Pages/Inventory"
));
const Library = React.lazy(() => import(
  /* webpackChunkName: "library" */ "components/Pages/Library"
));
const Users = React.lazy(() => import(
  /* webpackChunkName: "library" */ "components/Pages/Users"
));

export {
  Categories,
  Channels,
  Collections,
  Dashboard,
  Inventory,
  Library,
  Users,
};

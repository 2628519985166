// Reducers
import appReducer  from 'store/reducers/appReducer';
import userReducer from 'store/reducers/userReducer';

var reducers = {
    appState : appReducer,
    userState: userReducer,
};

export default reducers;

import * as API from 'api';

export function getFormats(searchValue = '', count = null)
{
    let options = {};

    if (typeof searchValue === 'string' && searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    if (typeof searchValue === 'object') {
        options = {
            params: searchValue
        };
    }

    if (count !== null) {
        options.params['size'] = count;
    }

    return API.requests.GET('admin/library/formats', options)
        .then(response => {
            return response.data;
        });
}

export function getFormatCodes(searchValue = '')
{
    let options = {};

    if (typeof searchValue === 'string' && searchValue !== '') {
        options = {
            params: {
                s: searchValue,
            },
        };
    }

    if (typeof searchValue === 'object') {
        options = {
            params: searchValue
        };
    }

    return API.requests.GET('admin/library/format-codes', options)
        .then(response => {
            return response.data;
        });
}

export function saveFormat(id, type, format, editing = false)
{
    let data = {
        data: {
            type:   type,
            format: format,
        },
    };

    return editing === false ?
        API.requests.POST('admin/library/formats', data) :
        API.requests.PUT(`admin/library/formats/${id}`, data);
}

export function saveFormatCode(id, code, mainId, subId, editing = false)
{
    let data = {
        data: {
            code:   code,
            mainId: mainId,
            subId:  subId,
        },
    };

    return editing === false ?
        API.requests.POST('admin/library/format-codes', data) :
        API.requests.PUT(`admin/library/format-codes/${id}`, data);
}

export function saveFormatAndCode(mainId, code, subFormat)
{
    return API.requests.POST('admin/library/format-and-codes', {
        data: {
            mainId:    mainId,
            code:      code,
            subFormat: subFormat,
        },
    });
}

import React           from 'react';
import { useForm }     from 'react-hook-form';
import { useHistory }  from 'react-router-dom';
import * as API        from 'api';
import DinAdmin        from 'components/Displays/DinAdmin';
import { getRoute }    from 'router/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup        from "yup";

export default function Login(props)
{
  const history = useHistory();

  const schema = yup.object({
    user: yup.string().required(),
    password: yup.string().required(),
  });

  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const doLogin = (data) => {
    API.user.login(data.user, data.password)
      .then(response => {
        const routeName = response.redirect || API.user.POST_LOGIN_ROUTE;

        return history.push(getRoute(routeName));
      })
      .catch(error => {
        setError('response', { message: error.data });
      });
  };

  return (
    <>
      <div className="container full-page">
        <div className="columns full-page is-centered is-vcentered">
          <div className="column is-two-fifths-tablet">
            <DinAdmin classNames="large mb-5" />
            <form onSubmit={handleSubmit(doLogin)}>
              <div className="card">
                <div className="card-content">
                  <div className="field">
                    <div className="control">
                      <input
                        className={`input${API.errors.className(errors, 'valueUser')}`}
                        placeholder="User / Email"
                        {...register('user', { required: true, message: 'derp' })}
                        type="text"
                      />
                    </div>
                    {API.errors.errorMessage(errors, 'user')}
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className={`input${API.errors.className(errors, 'valuePassword')}`}
                        placeholder="Password"
                        {...register('password', { required: true })}
                        type="password"
                      />
                    </div>
                    {API.errors.errorMessage(errors, 'password')}
                  </div>
                  <div className="field">
                    {API.errors.errorMessage(errors, 'response')}
                  </div>
                </div>
                <footer className="card-footer">
                  <p className="card-footer-item">
                    <button
                      className="button is-dark"
                      type="submit"
                    >
                      Vroom
                    </button>
                  </p>
                </footer>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="layout-main-footer">
        Ver: {process.env.APP_VERSION}
      </footer>
    </>
  )
}
